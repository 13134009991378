<template>
  <ProjectForm
      action="Update"
      title="Update Project"
      :formLoader="loading"
      :projectId="projectId"
      :form="data"
      @submit="submit"
  />
</template>

<script>
import ProjectForm from "@/views/components/projects/ProjectForm";
import ProjectService from "@/services/ProjectService";

export default {
  name: "Update",
  components: {ProjectForm},
  data() {
    return {
      projectService: new ProjectService(),
      loading: true,
      data: {},
      projectId: this.$route.params.id
    }
  },
  methods: {
    getProject() {
      this.projectService.show(this.projectId)
          .then(res => {
            this.data = res
          })
          .finally(() => {
            this.loading = false
          })
    },
    submit(data) {
      this.loading = true
      this.projectService.update(this.projectId, data)
          .then(res => {
            console.log(res)
            this.$router.push({name: 'all-projects'})
          })
          .finally(() => {
            this.loading = false
          })
    }
  },
  mounted() {
    this.getProject()
  }
}
</script>

<style scoped>

</style>
